
class CaseMetadataCtrl {

  private CaseDetailsService
  private AdNotification

  $rootScope
  $scope
  $filter
  $uibModal
  caseObject
  userObject
  departmentWatch
  FRONTEND_VUE_URL
  AdRequest
  pendingCrmSave = false;
  CrmIntegrationSlideOpen = false;
  loadingCrmEntity = true;
  crmProperties = [];
  showIframe = true

  $onInit() {
    this.setInfoObjects();
  }

  getExtraData(crmName: string) {
    if (!crmName) return {}
    const extraData = {}
    if (crmName === 'salesforce'){
      const contactId = this.getSalesforceContactId()
      if (contactId){
        extraData['contactId'] = contactId
    }
    }
    return extraData
  }

  getSalesforceContactId(){
    const { crm_integration=undefined } = this.userObject || {}
    if (!crm_integration){
      return ''
    }
    return crm_integration['salesforce_id'];
  }

  setInfoObjects() {
    this.userObject = this.userObject || this.CaseDetailsService.get('userObject')
    this.caseObject = this.caseObject || this.CaseDetailsService.get('caseObject')
  }

  $onDestroy() {
    this.departmentWatch()
    window.removeEventListener('message', this.handleIframeMessage);
  }

  handleIframeMessage = ({data}: MessageEvent) => {
    if (!(data.type === 'crmEvent' || data.type === 'metadataEvent')) {
      return;
    }
    const {message} = data;
    if(message.action === 'search') {
      this.showModal(message.data);
    }
    else if (message.action === "updateCase") {
      this.emitupdatedCase(message.data)
    }
    else if (message.action === "notifyCrmUpdate"){
      this.notifyCrmUpdate(message.data)
    }
    else if (message.action === "notifyDataUpdate"){
      this.notifyDataUpdate(message.data)
    }
    else if (message.action === "notifyCrmUnlink"){
      this.notifyCrmUnlink(message.data)
    }
    else if (message.action === 'requestColorScheme') {
      const colorScheme = localStorage.getItem('colorScheme');
      if (colorScheme) {
        this.changeIframeTheme(colorScheme)
      }
    }
  };

  notifyCrmUpdate(data) {
    const action = 'save_crm_properties'
    if (data.status === 'success') {
      this.AdNotification.success(200, action);
    }
    else{
      this.AdNotification.error({}, action);
    }
  }

  notifyCrmUnlink(data) {
    const action = 'unlink_crm_properties'
    if (data.status === 'success') {
      this.AdNotification.success({status: 200, response: {entity: 'ticket'}}, action);
    }
    else{
      this.AdNotification.error({response: {entity: 'ticket'}}, action);
    }
  }

  notifyDataUpdate(data){
    const action = 'add_custom_data_case'
    if (data.status === 'success') {
      this.AdNotification.success(201, action);
    }
    else {
      this.AdNotification.error({}, action);
    }
  }


  showModal(linkableData) {
    // we're not using the user data in vue. So contactId must be supplied here
    const extraData = this.getExtraData(linkableData['crmName'])
    const linkableDataCrm = {...linkableData, ...extraData}
    this.$uibModal
      .open({
        templateUrl: 'blocks/message/views/linkable_crm_modal.html',
        controller: 'LinkableCrmCtrl',
        resolve: {
          linkableDataCrm: () => {
            return linkableDataCrm;
          }
        },
        windowClass: 'modal'
      }).result.then(linked => {
        if (linked) {
          this.refreshCaseData()
        }
      });
  }

  changeIframeTheme(theme) {
    const message = {
      action: 'refreshTheme',
      data: {
        theme: theme
      }
    }
    this.sendMessageToIframe(message);
  }

  refreshCaseData() {
    const message = {
      action: 'refreshCaseData'
    }
    this.sendMessageToIframe(message);
  }

  sendMessageToIframe(message) {
    const iframe = document.getElementById('caseMetadataIframe') as HTMLIFrameElement | null;
    if (!iframe) {
      return;
    }
    iframe.contentWindow.postMessage(message, '*');
  }

  emitupdatedCase(updatedCase){
    // update fields instead of replacing everything
    Object.keys(updatedCase).forEach(key => {
      this.caseObject[key] = updatedCase[key];
    });
    this.$rootScope.$emit(
      'integration:window:refresh_case',
      angular.fromJson(this.caseObject)
    );
    const custom_fields = this.caseObject.custom_fields || [];
    this.caseObject.has_custom_data = custom_fields.length !== 0;
    this.$rootScope.$emit('column:actionOnCase', {
      action: 'updateCase',
      element: this.caseObject
    })

    this.$rootScope.$emit('caseMetadata:hasBeenUpdated');
  }


  constructor(
    $rootScope,
    $scope,
    $filter,
    AdNotification,
    $uibModal,
    $sce,
    FRONTEND_VUE_URL,
    AdRequest,
    CaseDetailsService
  ) {

    this.$rootScope = $rootScope
    this.$scope = $scope
    this.$filter = $filter
    this.$uibModal = $uibModal
    this.AdNotification = AdNotification
    this.CaseDetailsService = CaseDetailsService
    this.showIframe = true

    const caseUpdateListenerOff = $scope.$on('case:hasBeenUpdated', (event, caseObject) => {
      this.showIframe = false
      this.caseObject = caseObject
      this.showIframe = true
    })

    window.addEventListener('message', this.handleIframeMessage);

    const customDataSaveOff = $scope.$on('customDataBlock:saveDone', () => {
      $rootScope.$emit(
        'integration:window:refresh_case',
        angular.fromJson(this.caseObject)
      );
      const custom_fields = this.caseObject.custom_fields || [];
      this.caseObject.has_custom_data = custom_fields.length !== 0;
      $rootScope.$emit('column:actionOnCase', {
        action: 'updateCase',
        element: this.caseObject
      })

      $rootScope.$emit('caseMetadata:hasBeenUpdated');
    })

    $scope.iFrameUrl = () => {
      const token = AdRequest.getToken()
      const url = `${FRONTEND_VUE_URL}/frames/case/${this.caseObject.resource_id}/data?authToken=${token}`
      return $sce.trustAsResourceUrl(url)
    }
  
    const themeChanging = $rootScope.$on('colorSchemeChanged', (_, scheme) => {
      this.changeIframeTheme(scheme)
    });

    $scope.$on('$destroy', () => {
      caseUpdateListenerOff();
      customDataSaveOff();
      themeChanging();
    })
    this.departmentWatch = $scope.$watch(
      'this.caseObject.department_id',
      newValue => {
        $scope.$broadcast('customDataBlock:caseObject', this.caseObject);
      }
    )
  }
};

angular
  .module('postCenterWebClientApp')
  .controller('caseMetadataCtrl', CaseMetadataCtrl)
