'use strict';

var app = angular.module('postCenterWebClientApp');
app.directive('accountSelector', function () {
  return {
    templateUrl: 'blocks/analytics/views/directives/account_selector.html',
    restrict: 'E',
    controller: 'AccountSelectorCtrl',
    scope: {}
  }
});

app.controller('AccountSelectorCtrl', AccountSelectorCtrl);

AccountSelectorCtrl.$inject = ['$scope', '$rootScope', 'localStorageService', 'SocialAccountService','AdAvailableSn'];
function AccountSelectorCtrl($scope, $rootScope, localStorageService, SocialAccountService: SocialAccountService, AdAvailableSn) {

  var storageDSelectedAccount = 'analytics:dashbrdSelectedAccount'
  $scope.selectedAccount = localStorageService.get(storageDSelectedAccount);

  SocialAccountService.getSocialAccounts().then(accounts => {
    $scope.accountList  = angular.copy(accounts);
  })

  $scope.changeSocialAccount = function() {
    var emitKey = 'analytics:dashboard:changeAccount';
    $rootScope.$emit(emitKey, $scope.selectedAccount);
    localStorageService.set(storageDSelectedAccount, $scope.selectedAccount);
  };

  $scope.getSnName = function(sn) {
    return AdAvailableSn.getTitle(sn) || sn.replace("_", " ")
  }
}

