/* global i18nEpaAnalytics */
const epaTabs = {
  resume: {counters: 7},
  res: {counters: 4},
  csat: {counters: 3},
  ces: {counters: 3},
  fcr: {counters: 4},
  detail: {counters: 0}
}
const epaTabsNames = Object.keys(epaTabs)
const chartDefault = (key) => ({
  date: {
    filters: {
      metric: {tab: key, render: 'graph', query_kind: 'date'},
      date_unit: 'day'
    }
  },
  agent: {
    filters: {
      metric: {tab: key, render: 'graph', query_kind: 'agent'},
      limit: 5,
      order_by: [{field: 'percentage', sort: 'DESC'}]
    }
  },
  detail: {
    filters: {
      metric: {tab: key, render: 'table', query_kind: 'detail'}
    }
  }
})
const EpaAnalyticsDefaultState = () => ({
  tabs: epaTabsNames.map(tab => ({
    active: tab === 'resume',
    group: tab,
    name: i18nEpaAnalytics.t(`tabs.${tab}`),
    counters: epaTabs[tab].counters
  })),
  dataFilters: ['surveys', 'accounts', 'agents'].map(filter => {
    return {
      active: true,
      name: i18nEpaAnalytics.tc(`filters.${filter}`, 2),
      id: filter
    };
  }),
  availableAccounts: ['whatsapp', 'chat', 'facebook'],
  dataReady: false,
  empty: true,
  charts: epaTabsNames.reduce(
    (tabs, key) => ({
      ...tabs,
      [key]: chartDefault(key)
    }),
    {}
  ),
  counters: epaTabsNames.reduce((tabs, key) => ({...tabs, [key]: []}), {}),
  table: [],
  agents: [],
  surveys: [],
  filters: {
    range_from: moment().subtract(7, 'days').startOf('day').unix(),
    range_to: moment().unix(),
    accounts: [],
    surveys: [],
    departments: []
  },
  loading: false,
  countersLoading: false,
  chartsLoading: false,
  detailTableLoading: false,
});

store.registerModule('epaAnalytics', {
  namespaced: true,
  state: EpaAnalyticsDefaultState(),
  getters: {
    tabs: state => state.tabs,
    activeTab: state => state.tabs.find(tab => tab.active),
    activeTabName: (_state, getters) => getters.activeTab.group,
    getTabCharts: (state, getters) => state.charts[getters.activeTabName],
    getCountersTab: (state, getters) => state.counters[getters.activeTabName],
    dataFilters: state => state.dataFilters,
    activedFilter: state => filterToFind =>
      state.dataFilters.find(({id}) => id === filterToFind),
    empty: state => state.empty,
    user: state => state.user,
    surveys: state => state.surveys,
    table: state => state.table,
    filters: state => state.filters,
    dataReady: state => state.dataReady,
    countersLoading: state => state.countersLoading,
    chartsLoading: state => state.chartsLoading,
    detailTableLoading: state => state.detailTableLoading,
    loading: state => state.loading,
    departments: state => {
      let {departments} = state;
      if (state.user.isSupervisor) {
        departments = departments.filter(department =>
          state.user.profile.departments.includes(department.id)
        );
      }

      return departments.sort((sortA, sortB) => {
        return sortA.name.toLowerCase() > sortB.name.toLowerCase() ? 1 : -1;
      });
    },
    departmentsSelector: (state, getters) => {
      let filtered = getters.departments
        .filter(
          dep => (
            state.user.profile.departments.includes(dep.id) ||
            state.user.isAnalystOrAdmin
          )
        )
      if (state.user.isAnalystOrAdmin){
        filtered.unshift({id: 0, name: 'Sin departamento'})
      } 
      filtered.map(({id, name}, index) => {
          return {
            name,
            id,
            active: index === 0
          };
        });
      return filtered;
    }
  },
  mutations: {
    MUTATE(state, {property, value}) {
      state[property] = value;
    },
    MUTATE_CHARTS(state, {filters, results}) {
      const {
        metric: {tab, query_kind}
      } = filters;
      state.charts[tab] = {
        ...state.charts[tab],
        [query_kind]: {filters, results}
      };
    },
    MUTATE_COUNTERS(state, {tab, counters}) {
      state.counters[tab] = counters;
    },
    CLEAN_DATA(state) {
      state.empty = true;
      state.table = [];
    },
    SORT_TABLE(state, payload) {
      state.table.data_results.table_data = payload;
    },
    CLEAN_CHARTS(state, {filters}) {
      const {
        metric: {tab}
      } = filters;
      state.charts[tab] = chartDefault(tab);
    }
  },
  actions: {
    updateAccounts({commit}, payload) {
      commit('MUTATE', {
        property: 'accounts',
        value: payload
      });
    },
    updateUser({commit}, payload) {
      commit('MUTATE', {
        property: 'user',
        value: payload
      });
    },
    setFilters({commit, dispatch, state}, payload) {
      const {filters} = state;
      commit('MUTATE', {
        property: 'filters',
        value: {...filters, ...payload}
      });
      dispatch('getDataByTab');
    },
    agentsFilter({commit, dispatch, state}, payload) {
      // refactor
    },
    getResumeData({dispatch}) {
      dispatch('getAll');
    },
    getChartByRender(
      {dispatch, getters},
      {render: newRender, query_kind: queryKind}
    ) {
      const {
        getTabCharts: {
          [queryKind]: {
            filters: {metric, ...options}
          }
        }
      } = getters;
      dispatch('getCharts', {
        metric: {...metric, render: newRender},
        ...options
      });
    },
    getChartByAgents({dispatch, getters}, payload = {}) {
      const {
        metric: newMetric = {},
        order_by: updateOrderBy,
        limit: newLimit
      } = payload;
      const {
        activeTabName: tab,
        getTabCharts: {
          agent: {filters, results = null}
        }
      } = getters;

      if (!results) {
        if (tab === 'ces') {
          filters.order_by[0].field = 'average';
        }
        if (tab === 'fcr') {
          filters.order_by[0].field = 'fcr';
        }
        if (tab === 'res') {
          filters.order_by[0].field = 'capacity';
        }
      }
      dispatch('getCharts', {
        metric: {...filters.metric, ...newMetric},
        order_by: updateOrderBy ? [updateOrderBy] : filters.order_by,
        limit: newLimit !== undefined ? newLimit : filters.limit
      });
    },
    getChartByDate({dispatch, getters}, updatedDateUnit) {
      const {
        getTabCharts: {
          date: {
            filters: {metric, date_unit}
          }
        }
      } = getters;
      dispatch('getCharts', {
        metric,
        date_unit: updatedDateUnit || date_unit
      });
    },
    getAll({dispatch}) {
      dispatch('getCounters');
      dispatch('getChartByAgents');
      dispatch('getChartByDate');
    },
    getDataByTab({dispatch, getters}) {
      const {activeTabName} = getters;
      if (activeTabName !== 'detail') {
        dispatch('getAll');
      } else {
        dispatch('getTable');
      }
    },
    getTable({commit, dispatch}) {
      const metric = {tab: 'detail', render: 'table', query_kind: 'detail'};
      commit('MUTATE', {
        property: 'detailTableLoading',
        value: true
      });
      dispatch('getAnalytics', {metric})
        .then(response => {
          if (response) {
            const {results} = response;
            commit('MUTATE', {
              property: 'table',
              value: results
            });
          }
          else{
            commit('MUTATE', {
              property: 'table',
              value: []
            });
          }
        })
        .catch(() => {
          commit('MUTATE', {
            property: 'table',
            value: []
          });
        })
        .finally(() => {
          commit('MUTATE', {
            property: 'detailTableLoading', value: false
          });
        });
    },
    getCounters({commit, dispatch, getters}) {
      const {activeTabName: tab} = getters;
      const metric = {tab, render: 'counter', query_kind: 'counter'};
      commit('MUTATE', {
        property: 'countersLoading',
        value: true
      });
      dispatch('getAnalytics', {metric})
        .then(response => {
          const {
            results: {data_results}
          } = response;
          const filteredCounters = data_results.map(counter => {
            if (counter.type === 'integer') {
              counter.result = parseFloat(counter.result.toFixed(1));
            }
            if (counter.type === 'percentage') {
              counter.result = `${parseFloat(counter.result).toFixed(1)}%`;
            }
            return counter;
          });
          commit({
            type: 'MUTATE_COUNTERS',
            counters: filteredCounters,
            tab: metric.tab
          });
        })
        .catch(() => {
          commit('MUTATE', {
            property: 'counters',
            value: {}
          });
        }).finally(() => {
          commit('MUTATE', {property: 'countersLoading', value: false});
        });
    },
    getCharts(
      {commit, dispatch},
      {metric, order_by = null, date_unit = null, limit = null}
    ) {
      commit('CLEAN_CHARTS', {
        filters: {metric, order_by, date_unit, limit}
      });
      commit('MUTATE', {
        property: 'chartsLoading',
        value: true
      });
      dispatch('getAnalytics', {metric, order_by, date_unit, limit}).then(
        response => {
          if (response){
            const {
              results,
              filters: {render}
            } = response;
            if (render === 'graph' && results.id === 'bar_chart_date') {
              const {
                xAxis: {categories}
              } = results;
              results.xAxis.categories = categories.map(date =>
                moment.unix(date).utc().format('YYYY/MM/DD')
              );
            }
            commit('MUTATE_CHARTS', {
              results,
              filters: {metric, order_by, date_unit, limit}
            });
          } else {
            commit('CLEAN_CHARTS', {
              filters: {metric, order_by, date_unit, limit}
            });
          }
          commit('MUTATE', {
            property: 'chartsLoading',
            value: false
          });
      });
    },
    async getAnalytics({commit, state}, {metric, order_by, date_unit, limit}) {
      const {query_kind} = metric;
      const {filters} = state;
      let extraFilters = {};
      if (query_kind === 'agent') {
        extraFilters = {order_by, limit};
      }
      if (query_kind === 'date') {
        extraFilters = {date_unit};
      }
      const response = await this.EpaService.getAnalyticsSurveys({
        metric,
        ...filters,
        ...extraFilters
      });
      commit('MUTATE', {
        property: 'empty',
        value: false
      });
      commit('MUTATE', {
        property: 'dataReady',
        value: true
      });
      return response;
    },
    async getSurveys({commit}) {
      this.EpaService.getSurveys().then(surveys => {
        if (surveys) {
          const activeSurveys = surveys
            .filter(survey => survey.is_active)
            .map(survey => {
              const {id, name} = survey;
              return {
                id,
                name,
                active: false
              };
            })
            .sort((sortA, sortB) => {
              return sortA.name.toLowerCase() > sortB.name.toLowerCase()
                ? 1
                : -1;
            });
          commit('MUTATE', {
            property: 'surveys',
            value: activeSurveys
          });
        }
      });
    },
    async getCsvData(
      {commit, dispatch, getters, rootGetters},
      {query_kind, title}
    ) {
      const {
        filters,
        getTabCharts: {
          [query_kind]: {
            filters: {metric, ...options}
          }
        }
      } = getters;
      const {id: client_id} = rootGetters['establishment/establishment'];
      const token = this.EpaService.AdRequest.getToken();
      const env = this.EpaService.AdRequest.getEnv();
      const filename = title.replace(/ /g, '_').replace(/\./g, '').toLowerCase();
      commit('MUTATE', {property: 'loading', value: true});
      this.EpaService.exportSurveyCloud({
        client_id,
        filters: {
          ...filters,
          ...options,
          metric: {...metric, render: 'export'},
          lang: i18nEpaAnalytics.locale,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
        token,
        env: env
      })
      .then(resp => resp.blob())
      .then(blob => {
        dispatch('downloadExcelFile', {filename, blob})
      })
      .catch(error => {
        dispatch('notify', {
          message: i18nEpaAnalytics.t('notify.error.download'),
          type: 'error'
        },
        {root: true});
        throw new Error(error);
      })
      .finally(() => {
        commit('MUTATE', {property: 'loading', value: false});
      });
    },
    downloadExcelFile(_context, {filename, blob}) {
      const link = window.URL.createObjectURL(blob)
      const anchor = document.createElement('a')
      anchor.href = link
      anchor.download = filename
      document.body.appendChild(anchor)
      anchor.click()
      anchor.remove()
    },
    getDepartments({commit}) {
      return new Promise((resolve, reject) => {
        this.DepartmentService.getDepartments()
          .then(departments => {
            commit('MUTATE', {
              property: 'departments',
              value: departments
            });
            resolve(departments);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
  }
});
